







































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import md2html from '@/shared/mixins/markdown2html'
import { UserRole } from '../enum/general-enum'

export default defineComponent({
    props: {
        doc: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const HELP_CONTENT = ref('')
        const editable = ref(false)
        const { role } = useState(['role'])

        // set markdown editor data
        const setFormData = () => {
            HELP_CONTENT.value = props.doc.body
        }

        // save
        const save = () => {
            emit('save-doc', HELP_CONTENT.value)
            editable.value = false
        }
        // cancel
        const cancel = () => {
            editable.value = false
            if (props.doc) setFormData()
        }

        // watch for doc content change and set formdata
        watch(
            () => props.doc,
            () => {
                if (props.doc) setFormData()
            }
        )

        return {
            t,
            language,
            HELP_CONTENT,
            editable,
            save,
            md2html,
            utils,
            cancel,
            UserRole,
            role,
        }
    },
})
