



































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import HelpContent from '@/shared/components/HelpContent.vue'
import router from '@/router'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import helpQueries from '@/shared/queries/helpQueries'
import { useToast } from 'vue-toastification/composition'
import { HelpPage } from '@/shared/enum/help-enum'

export default defineComponent({
    props: {
        doc: {
            type: String,
        },
    },
    components: {
        HelpContent,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)
        const helpContent = ref({
            id: 0,
            key: '',
        })
        const toast = useToast()
        const helpContentType = ref('')

        // get help content
        const getHelpContent = async () => {
            const helpQuery = `
                    query helpPage($key:String){
                        helpPage(key: $key){
                          helpPage{ ${helpQueries.HELP_LIST} }
                        }
                    }
                
            `
            const helpVariables = {
                key: helpContentType.value,
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(helpQuery, helpVariables)
            } catch (err) {
                throw Error('Error while retrieving help content')
            }
            if (result && result.data.helpPage) {
                helpContent.value = result.data.helpPage.helpPage
            }
        }

        // save help content
        const save = async (content: string) => {
            const helpQuery = `
                    mutation updateHelpPage($id:ID, $input: HelpPageInput){
                        updateHelpPage(id: $id, input: $input){
                            status
                            error
                        }
                    }
                
            `
            const input = {
                body: content,
            }
            const helpVariables = {
                id: helpContent.value.id,
                input,
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(helpQuery, helpVariables)
            } catch (err) {
                toast.error(t('HELP_CONTENT_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving help content')
            }
            if (result.data && result.data.updateHelpPage.status) {
                toast.success(t('HELP_CONTENT_SAVED_SUCCESS_MESSAGE', language.value))
                getHelpContent()
            }
        }

        // watch for route change and find document type (`docType`)
        watch(
            () => router.app.$route,
            () => {
                /* read key from `docType` using HelpPage enum. Since Help index page is editable, 
                user cannot use ids with the links to load contents anymore. Hence using `key` names*/

                const docType: string | string[] = router.app.$route.query.docType
                if (typeof docType === 'string') helpContentType.value = HelpPage[docType]
                getHelpContent()
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            utils,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            visibleLeft,
            helpContent,
            save,
        }
    },
})
